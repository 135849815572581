import React from 'react';

import { AlertValue, PriceDetails, ValueTargets } from '../../interfaces';
import PeriodStatus from './PeriodStatus';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { formatDate } from '../../utils';

const isPriceDetails = (value: any): value is Partial<Record<('sell' | 'buy'), PriceDetails>> => {
  return (value?.buy && !Array.isArray(value?.buy)) || (value?.sell && !Array.isArray(value?.sell));
}

const objectStatusRow = (data: Partial<ValueTargets>) => {
  if (!data) return <></>;

  const detailRow = (details: PriceDetails) => {
    return Object.entries(details).map(([key, value], idx) => (
      <ul key={key} style={{ margin: 0, listStyle: 'none', padding: 0 }}>
        <li key={'details-' + idx} style={{ margin: 0 }}>
          {key}:{' '}
          {Object.entries(value).map(([num, ids], idx) => (
            <span key={`detail-${idx}`}>
              {' '}
              {num}: {ids.toString()} |
            </span>
          ))}
        </li>
      </ul>
    ));
  };

  const mapRows = (value: any) => {
    if (isPriceDetails(value)) {
      return (
        <ul key={'values'} style={{ margin: 0, padding: 0 }} >
          {value?.buy && <li>{detailRow(value?.buy)}</li>}
          {value?.sell && <li>{detailRow(value?.sell)}</li>}
        </ul>
      )
    } else {
      return <li>{detailRow(value)}</li>
    }
  }

  return (
    <ul style={{ margin: 0 }}>
      {Object.entries(data).map(([key, value], idx) => (
        <li key={'details-' + idx}>
          {key}: {value && mapRows(value)}
        </li>
      ))}
    </ul>
  );
};

export const CurrentStatusDataRows = ({ data }: { data: AlertValue }) => {
  const mapValue = (key: string, value: any) => {
    if (key === 'period') {
      return <PeriodStatus period={value} />;
    } else if (typeof value === 'boolean') {
      return value?.toString();
    } else if (key === 'updated') {
      return formatDate(value);
    } else if (typeof value === 'number') {
      return value.toFixed(5);
    } else if (typeof value === 'object') {
      return objectStatusRow(value);
    } else {
      return value;
    }
  };

  const rows = Object.entries(data).map(([key, value], idx) => ({
    param: key,
    value: mapValue(key, value)
  }));

  return (
    <>
      {rows.map((row, idx) => (
        <TableRow key={idx}>
          <TableCell>{row.param}</TableCell>
          <TableCell>{row.value}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

const CurrentStatus = ({ row }: { row: AlertValue }) => {
  return (
    <>
      <TableCell>{row.online?.toString()}</TableCell>
      <TableCell>{typeof row.direction === 'number' ? `${row.direction * 100}%` : row.direction}</TableCell>
      <TableCell>{row.wave}</TableCell>
      <TableCell>{row.tracer}</TableCell>
      <TableCell>{row.timma}</TableCell>
      <TableCell>{row.bvol}</TableCell>
      <TableCell>{row.half}</TableCell>
      <TableCell>{row.current?.toFixed(5)}</TableCell>
      <TableCell>{row.current_ask?.toFixed(5)}</TableCell>
      <TableCell>{row.current_bid?.toFixed(5)}</TableCell>
      <TableCell>{row.up?.toFixed(5)}</TableCell>
      <TableCell>{row.up_ask?.toFixed(5)}</TableCell>
      <TableCell>{row.up_bid?.toFixed(5)}</TableCell>
      <TableCell>{row.down?.toFixed(5)}</TableCell>
      <TableCell>{row.down_ask?.toFixed(5)}</TableCell>
      <TableCell>{row.down_bid?.toFixed(5)}</TableCell>
      <TableCell>{formatDate(row.updated)}</TableCell>
      <TableCell>{row.base_down?.toFixed(5)}</TableCell>
      <TableCell>{row.base_up?.toFixed(5)}</TableCell>
      <TableCell>{objectStatusRow(row.value_down)}</TableCell>
      <TableCell>{objectStatusRow(row.value_up)}</TableCell>
      <PeriodStatus period={row.period} />
    </>
  );
};
export default CurrentStatus;

export const CurrentStatusHeader = () => {
  return (
    <>
      <TableCell>Online</TableCell>
      <TableCell>Direction</TableCell>
      <TableCell>Wave</TableCell>
      <TableCell>Tracer</TableCell>
      <TableCell>Timma</TableCell>
      <TableCell>Bvol</TableCell>
      <TableCell>Half</TableCell>
      <TableCell>Current</TableCell>
      <TableCell>Current ask</TableCell>
      <TableCell>Current bid</TableCell>
      <TableCell>Up</TableCell>
      <TableCell>Up ask</TableCell>
      <TableCell>Up bid</TableCell>
      <TableCell>Down</TableCell>
      <TableCell>Down ask</TableCell>
      <TableCell>Down bid</TableCell>
      <TableCell>Updated</TableCell>
      <TableCell>Base Down</TableCell>
      <TableCell>Base Up</TableCell>
      <TableCell>Values Down</TableCell>
      <TableCell>Values Up</TableCell>
      <TableCell>Period</TableCell>
    </>
  );
};
