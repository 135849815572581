import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert } from '../../interfaces';
import CurrentStatus, { CurrentStatusHeader } from './CurrentStatus';
import IconButton from '@mui/material/IconButton';
import { Box, Collapse, TableContainer } from '@mui/material';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDate } from '../../utils';

type ListProps = {
  list: Alert[];
};
export const EventsTable = ({ list }: ListProps) => {
  return (
    <TableContainer component={Box} style={{ overflowX: 'auto' }}>
      <Table size="small" sx={{ width: '100%' }}>
        <TableHead>
          <TableRow key={'header'}>
            <TableCell></TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <Row row={row} key={row._id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function Row(props: { row: Alert }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.key}
        </TableCell>
        <TableCell>{formatDate(row.created_date)}</TableCell>
        <TableCell>
          {row.event.type} - {row.event.value} { row.event.checker &&  `| ch: ${row.event.checker} / max: ${row.event.max}` }
        </TableCell>
        <TableCell style={{ whiteSpace: 'wrap' }}>{row.messages?.join('\n')}</TableCell>
      </TableRow>
      {row.current ?
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Cache value
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Cache Value</TableCell>
                    <CurrentStatusHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Current</TableCell>
                    <CurrentStatus row={row.current} />
                  </TableRow>
                  <TableRow>
                    <TableCell>Before</TableCell>
                    <CurrentStatus row={row.before} />
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      : null }
    </React.Fragment>
  );
}
