import * as React from 'react';
import Title from '../Title';
import { Factors } from '../../interfaces';
import { getFactors, updateFactors } from '../../RestApi';
import { Box, FormGroup, Grid, TextField, Button } from '@mui/material';

export default function FactorManager() {
  const [factors, setFactors] = React.useState<Factors>({});
  const [status, setStatus] = React.useState<string | null>('loading......');

  function handleFactorSubmit(event: React.FormEvent<HTMLFormElement>) {
    setStatus('loading......');
    event.preventDefault();

    const factors: Factors = {};

    const formData = new FormData(event.currentTarget);
    formData.forEach((value, key) => {
      const [factor, type] = key.split('_');
      if (factors[factor]) {
        factors[factor] = { ...factors[factor], [type]: value };
      } else {
        factors[factor] = { [type]: value };
      }
    });

    updateFactors({ factors })
      .then((res) => {
        setStatus('Updated OK');
      })
      .catch((err) => {
        setStatus('Update failed: ' + JSON.stringify(err));
      });
  }

  React.useEffect(() => {
    getFactors()
      .then((res) => {
        setFactors(res);
        setStatus(null);
      })
      .catch((err) => {
        console.error(err);
        setStatus(JSON.stringify(err));
      });
  }, []);

  const addNewValue = () => {
    const num = Object.keys(factors).length + 1;
    setFactors({
      ...factors,
      [String(num)]: { tp: undefined, sl: undefined, bt: undefined, open: undefined }
    });
  };
  const removeValue = () => {
    delete factors[Object.keys(factors).length];
    setFactors({ ...factors });
    console.log(factors);
  };

  return (
    <React.Fragment>
      <Title>Change the factors</Title>
      <p>{status}</p>
      <Box component="form" onSubmit={handleFactorSubmit}>
        <div>
          <Grid container direction="row">
            {Object.keys(factors).map((factor, idx) => (
              <FormGroup key={'factor' + idx}>
                <h2>{factor}</h2>
                <Grid container item xs={6} direction="column" key={`factor-${idx}`}>
                  <TextField
                    margin="normal"
                    id={`${factor}_tp`}
                    label="tp"
                    name={`${factor}_tp`}
                    autoFocus
                    defaultValue={factors[factor].tp}
                    required
                  />
                  <TextField
                    margin="normal"
                    id={`${factor}_sl`}
                    label="sl"
                    name={`${factor}_sl`}
                    autoFocus
                    defaultValue={factors[factor].sl}
                    required
                  />
                  <TextField
                    margin="normal"
                    id={`${factor}_bt`}
                    label="bt"
                    name={`${factor}_bt`}
                    autoFocus
                    defaultValue={factors[factor].bt}
                    required
                  />
                  <TextField
                    margin="normal"
                    id={`${factor}_open`}
                    label="open"
                    name={`${factor}_open`}
                    autoFocus
                    defaultValue={factors[factor].open}
                  />
                </Grid>
              </FormGroup>
            ))}
          </Grid>
          <Button onClick={() => addNewValue()}>Add new</Button>
          <Button onClick={() => removeValue()}>Remove latest</Button>
        </div>
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Update factors
        </Button>
      </Box>
    </React.Fragment>
  );
}
