import * as React from 'react';
import Title from '../Title';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Alert } from '../../interfaces';
import { getLatestAlerts } from '../../RestApi';
import Button from '@mui/material/Button';
import { EventsTable } from './EventsTable';

export default function Events() {
  const [page, setPage] = React.useState<number>(0);
  const [list, setList] = React.useState<Alert[]>([]);
  const LIMIT = 10;
  const dataFetchedRef = React.useRef(false);
  const [status, setStatus] = React.useState<string | null>('loading');

  async function fetchData() {
    return await getLatestAlerts(false, page, LIMIT).then((res) => {
      setList((prevState) => prevState.concat(res));
      setPage(page + LIMIT);
    });
  }

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData()
      .then(() => setStatus(null))
      .catch((err) => {
        setStatus(JSON.stringify(err));
      });
  }, []);

  async function getMore(event: React.MouseEvent) {
    event.preventDefault();
    setStatus('loading');
    fetchData().then(() => setStatus(null));
  }

  const isLoading = status === 'loading';
  const error = status !== 'loading' && status !== null;

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Title>History</Title>
        {isLoading ? (
          <p>..loading</p>
        ) : (
          <>
            {error && <p>{status}</p>}
            <EventsTable list={list} />
            <Button color="primary" onClick={getMore} sx={{ mt: 3 }}>
              See more orders....
            </Button>
          </>
        )}
      </Paper>
    </Grid>
  );
}
