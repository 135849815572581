import React from 'react';
import { Period, Time } from '../../interfaces';

export const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const PeriodStatus = ({ period }: { period?: Partial<Period> }) => {
  if (!period) return <></>;
  const timeSchedule = (time: Time[]) => {
    return time.map((time, idx) => (
      <span key={`time-${idx}`}>
        {' '}
        {time.start}-{time.end} UTC |
      </span>
    ));
  };

  return (
    <ul style={{ margin: 0 }}>
      {Object.entries(period).map(([key, value], idx) => (
        <li key={'time-' + idx}>
          {days[parseInt(key)]}: {timeSchedule(value)}
        </li>
      ))}
    </ul>
  );
};
export default PeriodStatus;
